import React, { useState } from 'react'
import DatePicker from 'react-date-picker'
import { Form, Container, Button, Alert } from 'react-bootstrap'
import { API_URL } from '../config/global'

const axios = require('axios');

function Setup() {

    const [selectedGender, setSelectedGender] = useState(null);
    const [birthday, setBirthday] = useState(null);
    const [selectedInterests, setSelectedInterests] = useState([]);
    
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const genders = [
        'male',
        'female',
        'other'
    ]

    const interests = [
        'pets',
        'beauty & fashion',
        'funny',
        'vlogs',
        'drama & acting',
        'dance',
        'family & parenting',
        'food & drink',
        'pop culture',
        'music',
        'technology & science',
        'sports',
        'art',
        'relationships',
        'anime & cartoons',
        'diy & home decor',
        'cars',
        'pranks',
        'jobs & careers',
        'tutorials',
        'gaming',
        'men\'s fashion',
        'outdoors',
        'extreme sports',
        'health & fitness',
        'oddly satisfying',
        'life hacks',
        'photography',
        'travel',
        'business'
    ]

    const interestCheckboxToggled = (checked, value) => {
        if(checked) {
            setSelectedInterests((prev) => [...prev, value]);
        } else {
            setSelectedInterests(selectedInterests.filter((interest) => interest !== value));
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');
        setLoading(true);

        if(!selectedGender) {
            setError('Please enter your gender!');
            window.scrollTo(0, 0);
            setLoading(false);
        } else if (!birthday) {
            setError('Please enter your birthday!');
            window.scrollTo(0, 0);
            setLoading(false);
        } else if (selectedInterests.length < 5) {
            setError('Please select at least 5 interests!');
            window.scrollTo(0, 0);
            setLoading(false);
        } else {
            await axios.post(API_URL + '/auth/setup', {
                gender: selectedGender,
                birthday: birthday,
                interests: selectedInterests
            }, {
                withCredentials: true
            }).then((res) => {
                window.open('/', '_self');
            }).catch((err) => {
                setLoading(false);
                setError(err.response);
            });
        }
    }

    if(loading) {
        return (
            <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: "100vh" }}>
                <p style={{ fontSize: '150px' }}>loading...</p>
            </Container>
        )
    } else {
        return (
            <Container className="d-flex align-items-center justify-content-center">
                <Form onSubmit={ handleSubmit }>
                    <h1>final setup questions</h1>
                    { error && <Alert variant={ 'danger' }>{ error }</Alert> }
                    <Form.Label>
                        gender    
                    </Form.Label>
                    <Form.Group>
                        {genders.map((gender, key) => (
                            <Form.Check
                                key={ key }
                                type={ 'radio' }
                                label={ gender }
                                value={ gender }
                                inline
                                disabled={ loading }
                                onChange={ (event) => setSelectedGender(event.currentTarget.value) }
                                name="gender"
                            />
                        ))}
                    </Form.Group>
    
                    <Form.Label>
                        birthday
                    </Form.Label>
                    <Form.Group>
                        <DatePicker
                            value={ birthday }
                            onChange={ setBirthday }
                            disableCalendar={ true }
                            disabled={ loading }
                            dayPlaceholder="dd"
                            monthPlaceholder="mm"
                            yearPlaceholder="yyyy"
                            maxDate={new Date('2021-12-17T03:24:00')}
                        />
                    </Form.Group>
    
                    <Form.Label>
                        interests
                    </Form.Label>
                    <Form.Group>
                        {interests.map((interest, key) => (
                            <Form.Check
                                key={ key }
                                type={ 'checkbox' }
                                label={ interest }
                                value={ interest }
                                onChange={ (event) => interestCheckboxToggled(event.currentTarget.checked, event.currentTarget.value) }
                                name="gender"
                                disabled={ loading }
                            />
                        ))}
                    </Form.Group>
                    <Button type="submit" disabled={ loading }>submit</Button>
                </Form>
            </Container>
        )
    }
}

export default Setup
