import React, { useState, useEffect } from 'react'
import { Button, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import { API_URL } from '../config/global'
const axios = require('axios');

export default function Dashboard() {

    const { logout } = useAuth();
    const [numSaved, setNumSaved] = useState();
    const [loading, setLoading] = useState(true);

    const MIN_NUM_SAVED = 0;

    function handleLogout() {
        logout();
    }

    useEffect(() => {
        axios.get(API_URL + '/auth/user', {
            withCredentials: true
        }).then((res) => {
            setNumSaved(res.data.user.numSaved);
            setLoading(false);
        }).catch((err) => {
            console.log(err);
        });
    }, []);

    if(loading) {
        return (
            <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: "100vh" }}>
                <p style={{ fontSize: '150px' }}>loading...</p>
            </Container>
        )
    } else if(numSaved >= MIN_NUM_SAVED) {
        return (
            <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: "100vh" }}>
                <div className="w-100 text-center">
                    <Link style={{ fontSize: '75px', textDecoration: 'none' }} to='/board'>view your boards</Link>
                    <br></br>
                    <Link style={{ fontSize: '75px', textDecoration: 'none' }} to='/rate'>rate other posts</Link>
                    <br></br>
                    <Button onClick={ handleLogout } style={{ fontSize: '37px', textDecoration: 'none', outline: 'none', boxShadow: 'none' }} variant={'link'}>logout</Button>
                </div>
            </Container>       
        )
    } else {
        return (
            <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: "100vh" }}>
                <div className="w-100 text-center">
                    <Link style={{ fontSize: '75px', textDecoration: 'none' }} to='/board'>view your boards</Link>
                    <br></br>
                    <p style={{ fontSize: '75px', textDecoration: 'none' }}>{`save ${MIN_NUM_SAVED - numSaved} more post(s) to rate`}</p>
                    <Button onClick={ handleLogout } style={{ fontSize: '37px', textDecoration: 'none', outline: 'none', boxShadow: 'none' }} variant={'link'}>logout</Button>
                </div>
            </Container>       
        )
    }
}
