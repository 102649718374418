import React from 'react'
import { Redirect } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import { isMobile, isChrome } from 'react-device-detect'
import { Container } from 'react-bootstrap';

import Login from './Login';
import Dashboard from './Dashboard';

export default function Home() {

    const { currentUser } = useAuth();

    // if(isMobile) {
    //     return (
    //         <Container className="d-flex align-items-center justify-content-center text-center" style={{ minHeight: "100vh" }}>
    //             <h1>You must use a computer to view this site.</h1>
    //         </Container>
    //     )
    // } else if (!isChrome) {
    //     return (
    //         <Container className="d-flex align-items-center justify-content-center text-center" style={{ minHeight: "100vh" }}>
    //             <h1>You must use Google Chrome to view this site. You can download Chrome <a style={{ textDecoration: 'none' }} href="https://www.google.com/chrome/">here</a>.</h1>
    //         </Container>
    //     )
    // } else if (!currentUser) {
    if (!currentUser) {
        return <Login />
    } else if(!currentUser.gender) {
        return <Redirect to='/setup' />
    } else {
        return <Dashboard />
    }
}
