import React, { useContext, useState, useEffect } from 'react'
import { API_URL } from '../config/global'
const axios = require('axios')

const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {

    const [currentUser, setCurrentUser] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = axios.get(API_URL + '/auth/user', { withCredentials: true }).then((res) => {
            setCurrentUser(res.data.user);
            setLoading(false);
        }).catch((err) => {
            console.log(err);
            setLoading(false);
        });

        return unsubscribe;
    }, []);

    function login() {
        return window.open(API_URL + '/auth/google', '_self');;
    }

    function logout() {
        return window.open(API_URL + '/auth/logout', '_self');;
    }


    const value = {
        currentUser,
        login,
        logout
    }

    return (
        <AuthContext.Provider value={ value }>
            { !loading && children }
        </AuthContext.Provider>
    )
}
