import React, { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import InnerHTML from 'dangerously-set-html-content'
import { API_URL } from '../config/global';
const axios = require('axios');

function Board(props) {

    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if(posts) {

            if(window.twttr) {
                setTimeout(() => {
                    window.twttr.widgets.load();
                }, 50);
            }
            
            if (window.instgrm) {
                setTimeout(() => {
                    window.instgrm.Embeds.process();
                }, 50);
            }
        }
    }, [posts]);

    useEffect(() => {
        axios.get(API_URL + '/board/posts', {
            params: {
                board: props.match.params.board
            },
            withCredentials: true
        }).then((res) => {
            setPosts(res.data);
            setLoading(false);
        }).catch((err) => {
            console.log(err);
        });
    }, [props.match.params.board]);

    if(loading) {
        return (
            <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: "100vh" }}>
                <p style={{ fontSize: '150px' }}>loading...</p>
            </Container>
        )
    } else {
        return (
            <Container>
                <h1 style={{ fontSize: '100px' }} className="d-flex justify-content-center"><Link style={{ textDecoration: 'none' }} to={'/'}>home</Link>/<Link style={{ textDecoration: 'none' }} to={'/board'}>boards</Link>{'/' + props.match.params.board}</h1>
                {
                    posts.map((post, key) => (
                        <InnerHTML className="d-flex justify-content-center" key={ key } html={post.oembed.html} />
                    ))
                }
            </Container>
        )
    }
}

export default Board