import React, { useState, useEffect } from 'react'
import { Button, Container } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom'
import { API_URL } from '../config/global';
import InnerHTML from 'dangerously-set-html-content' 
const axios = require('axios');

function Rate() {

    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);
    const [savedEnoughPosts, setSavedEnoughPosts] = useState(true);
    const MIN_NUM_SAVED = 0;

    useEffect(() => {
        if(post) {
            if(post.origin === 'twitter' && window.twttr) {
                setTimeout(() => {
                    window.twttr.widgets.load();
                }, 50);
            } else if (post.origin === 'instagram' && window.instgrm) {
                setTimeout(() => {
                    window.instgrm.Embeds.process();
                }, 50);
            }
        }
    }, [post])

    useEffect(() => {

        axios.get(API_URL + '/auth/user', {
            withCredentials: true
        }).then((res) => {
            if(res.data.user.numSaved < MIN_NUM_SAVED) {
                setSavedEnoughPosts(false);
                setLoading(false);
                return <Redirect to="/" />
            } else {
                axios.get(API_URL + '/post/rate', {
                    withCredentials: true
                }).then((res) => {
                    setPost(res.data);
                    setLoading(false);
                }).catch((err) => {
                    setLoading(false)
                })
            }
        });
    }, []);

    function makeRating(rating) {
        setLoading(true);
        axios.post(API_URL + '/post/rate', {
            rating: rating,
            postId: post._id
        }, {
            withCredentials: true
        }).then((res) => {  

            axios.get(API_URL + '/post/rate' , {
                withCredentials: true
            }).then((res) => {
                setPost(res.data);
                setLoading(false);
            }).catch((err) => {
                setLoading(false);
                setPost(null);
            })


        }).catch((err) => {
            setLoading(false);
            setPost(null);
        })
    }

    if(loading) {
        return (
            <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: "100vh" }}>
                <p style={{ fontSize: '150px' }}>loading...</p>
            </Container>
        )
    } else if (!savedEnoughPosts) {
        return <Redirect to='/' />
    } else if (post) {
        return (
            <Container className="text-center">
                <h1 style={{ fontSize: '100px' }}><Link style={{ textDecoration: 'none' }} to={'/'}>home</Link>/rate</h1>
                <p style={{ fontSize: '36px' }}>tell us if this is a post you would like to see! note: some posts may take longer to load based on your internet connection.</p>
                <InnerHTML className="d-flex justify-content-center" html={post.oembed.html} />
                <Container className="d-flex justify-content-center">
                    <Button className="mb-3" variant={'success'} style={{ minWidth: '200px' }} onClick={ (event) => makeRating(1) }>Yes</Button>
                    <Button className="mb-3" style={{ minWidth: '200px' }} onClick={ (event) => makeRating(-1) } variant={'danger'}>No</Button>
                </Container>
            </Container>
        )
    } else {
        return (
            <Container className="d-flex align-items-center justify-content-center">
                <div className="text-center">
                    <h1 style={{ fontSize: '100px' }}><Link style={{ textDecoration: 'none' }} to={'/'}>home</Link>/rate</h1>
                    <p style={{ fontSize: '75px', maxWidth: '1250px' }}>you've rated all the posts saved in our database!</p>
                    <p style={{ fontSize: '75px', maxWidth: '1250px' }}>come back later or continue saving posts yourself.</p>
                </div>
            </Container>
        )
    }
}

export default Rate
