import { AuthProvider } from './contexts/AuthContext';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import PrivateRoute from './components/PrivateRoute';

import Home from './pages/Home'
import Setup from './pages/Setup'
import SavedBoards from './pages/SavedBoards';
import Board from './pages/Board';
import Rate from './pages/Rate';

function App() {
  	return (
		<Router>
			<AuthProvider>
				<Switch>
					<Route exact path="/" component={ Home } />
					<PrivateRoute path="/setup" component={ Setup } />
					<PrivateRoute exact path="/board" component={ SavedBoards } />
					<PrivateRoute path="/board/:board" component={ Board } />
					<PrivateRoute path="/rate" component={ Rate } />
				</Switch>
			</AuthProvider>
		</Router>
	);
}

export default App;
