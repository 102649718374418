import React from 'react'
import { useAuth } from '../contexts/AuthContext'
import { Redirect } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { API_URL } from '../config/global';

export default function Login() {

    const { currentUser } = useAuth();

    if(currentUser) {
        return <Redirect to='/' />
    }

    return (
        <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: "100vh" }}>
            <div className="w-100 text-center">
                <h1 style={{ fontSize: '100px' }}>help build our ai system</h1>
                <p style={{ fontSize: '75px' }}>1. watch <a style={{ textDecoration: 'none' }} rel="noreferrer" target="_blank" href="https://www.youtube.com/watch?v=snJQxniPcFQ&ab_channel=AndrewWang">this</a> video</p>
                <p style={{ fontSize: '75px' }}>2. download <a style={{ textDecoration: 'none' }} rel="noreferrer" target="_blank" href="https://chrome.google.com/webstore/detail/social-save/bfhfffhfjlkikdkhpnbdpfodbaabpabb">this</a> extension (computer only)</p>
                <p style={{ fontSize: '75px' }}>3. click <a style={{ textDecoration: 'none' }} target="_self" href={API_URL + '/auth/google'}>this</a> to login</p>
            </div>
        </Container>
    )
}
