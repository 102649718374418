import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { API_URL } from '../config/global';
const axios = require('axios');

function SavedBoards() {

    const [boards, setBoards] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get(API_URL + '/board', {
            withCredentials: true
        }).then((res) => {
            setBoards(res.data);
            setLoading(false);
        }).catch((err) => {
            console.log(err);
        });
    }, []);

    if(loading) {
        return (
            <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: "100vh" }}>
                <p style={{ fontSize: '150px' }}>loading...</p>
            </Container>
        )
    } else if (boards.length === 0) {
        return (
            <Container className="text-center">
                <h1 style={{ fontSize: '100px' }}><Link style={{ textDecoration: 'none' }} to={'/'}>home</Link>/boards</h1>
                <p style={{ fontSize: '50px' }}>you haven't saved any boards yet.</p>
                <p style={{ fontSize: '50px' }}>save some posts to cheer this dog up!</p>
                <img style={{ maxWidth: '500px' }} src="https://s3.amazonaws.com/cdn-origin-etr.akc.org/wp-content/uploads/2019/07/15092404/do-dogs-grieve-other-dogs.jpg" alt="sad dog"></img>
            </Container>
        )
    } else {
        return (
            <Container >
                <h1 style={{ fontSize: '100px' }} className="text-center"><Link style={{ textDecoration: 'none' }} to={'/'}>home</Link>/boards</h1>
                {
                    boards.map((board, key) => (
                        <div className="d-flex justify-content-center" key={ key }>
                            <Link style={{ textDecoration: 'none', fontSize: '50px' }} to={ '/board/' + board.name }>{ board.name }</Link>
                        </div>
                    ))
                }
            </Container>
        )
    }
}

export default SavedBoards
